// Functions for validating inputs.
export default function () {
  return {
    // Trigger Ticket Generation for an order
    // AI GENERATION PROMPT
    // Write a typescript function to check if a string matches the following rules:
    // - Is 14 characters long
    // - Starts with the character "W"
    // - "W" is followed by a date in numeric format DDMMYY
    // - DDMMYY is followed by 7 uppercase alphanumeric characters
    validateOrderNumber: (input: string): boolean => {
      if (input.length !== 14 || !input.startsWith('W')) {
        return false;
      }

      const date = input.substr(1, 6);
      const alphanumeric = input.substr(7);

      const dateRegex = /^\d{2}\d{2}\d{2}$/;
      const alphanumericRegex = /^[A-Z0-9]{7}$/;

      return dateRegex.test(date) && alphanumericRegex.test(alphanumeric);
    }

  }
}
