// This is just a proxy for the $notify notifications plugin at the moment. 

export type WeTixNotification = {
    id?: string,
    type?: string,
    message: string,
    title?: string,
    persistent?: boolean
    timeout?: number
}


export const useNotify = (notification: WeTixNotification) => {
    const { $notify } = useNuxtApp()
    return $notify(notification)
}